<template>
    <p
        v-if="getText()?.trim().length"
        :class="{
            'text-lg leading-8 md:text-base md:leading-7': size === 'base',
            'text-xl leading-loose md:text-lg': size === 'large',
            'text-base md:text-sm': size === 'small',
        }"
        v-html="getText()"
    ></p>
    <p
        v-else
        :class="{
            'text-lg leading-8 md:text-base md:leading-7': size === 'base',
            'text-xl leading-loose md:text-lg': size === 'large',
            'text-base md:text-sm': size === 'small',
        }"
    >
        <slot />
    </p>
</template>

<script lang="ts">
import { sanitize } from '@/utility/Helpers';

export default defineNuxtComponent({
    props: {
        text: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            required: false,
            default: 'base',
            validator: (type: string) => {
                const types = ['base', 'large', 'small'];
                if (types.includes(type)) return true;
                else return false;
            },
        },
    },
    methods: {
        sanitize,
        getText() {
            if (typeof this.text === 'string') return sanitize(this.text);
        },
    },
});
</script>
